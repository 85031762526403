<template>
  <div class="tutorial-syphon">
    <p
      v-for="title in pageTitle"
      :key="title"
      class="page-title"
      v-text="title"
    ></p>
    <div class="rows">
      <video ref="video" controls loop playsinline>
        <source src="./assets/video.mp4" type="video/mp4" />
      </video>
      <img v-src="require('./assets/01.png')" alt="" />
      <p>
        我们致力于提供平台的玩家享受更快更好的客户服务，除此之外，为了确保您的个人资料能够安全无虞，在多方的测试评鉴之后，将”SYPHON”
        通讯软件，成为我们与玩家之间最好的沟通管道。
      </p>
      <img v-src="require('./assets/02.png')" alt="" />
      <p class="title">更加私密安全的沟通</p>
      <p>
        ”SYPHON”
        通讯软件，设计目标就是以私密性和用户体验去做软件的开发，而在我们一段时间的使用测试后，”SYPHON”
        在使用介面上更简洁及人性化，也能更简易的操作，在私密性上所有对话内容提供端对端加密，开发商也不会从中分析资料、跟踪或遥测。整个对话的使用过程中也表现的非常流畅，所以在稳定性上，也是相对上最好的软件。
      </p>
      <p class="title">VIP专属的即时性服务</p>
      <p>
        使用”SYPHON”
        的对谈方式是以一对一的模式，所以在与玩家的交流上，更能够针对不同的需求，得到快速精准的VIP专属服务，以及24小时的即时对谈！
      </p>
      <p>
        因此到目前为止，我们可以说”SYPHON”
        的安全和隐私方面已经超过目前的许多通讯软件，可以作为现行最新最好的软件选项，让玩家在服务上能得到客制化的美好体验。
      </p>
      <p class="title">如何开始使用</p>
      <p>
        前面有提到”SYPHON”
        的介面是非常简易及人性化，现在就跟着我们开始进行操作吧！
      </p>
      <p class="subtitle">
        1. 首先请于平台首页，进入联络我们，选择您的系统版本下载软件
      </p>
      <img v-src="require('./assets/03.png')" alt="" class="snap" />
      <img v-src="require('./assets/04.png')" alt="" class="snap" />
      <p class="red">✶ 也可以从下方连结下载Syphon 软件</p>
      <p>苹果iOS 版本下载</p>
      <p>
        <a :href="$store.state.syphon.ios">{{ $store.state.syphon.ios }}</a>
      </p>
      <p>安卓Android 版本下载</p>
      <p>
        <a :href="$store.state.syphon.android">{{
            $store.state.syphon.android
          }}</a>
      </p>
      <p class="subtitle">
        2. 软件下载完成后不需注册或做任何动作，立即与在线客服联系
      </p>
      <img v-src="require('./assets/05.png')" alt="" class="snap" />
      <p class="subtitle">3. 在线客服将会提供您个人专属的聊天软件账号</p>
      <img v-src="require('./assets/06.png')" alt="" class="snap" />
      <p class="subtitle">4.点击Syphon 将软件开启</p>
      <img v-src="require('./assets/07.png')" alt="" class="snap" />
      <p class="subtitle">
        5. 进入画面后，点击右下方框内绿色文字『<span class="lightgreen"
      >Login</span
      >』
      </p>
      <img v-src="require('./assets/08.png')" alt="" class="snap" />
      <p class="subtitle">
        6. 久按出现Paste
        ，即可将在线客服提供给您的帐号贴上于栏位，输入提供密码，点击Log in
        <span class="red">注意</span
        >所有符号及数字、字母是否都有复制完整如：<span class="red"
      >@xxx:weixin.cfd</span
      >完整帐号前方一定会有<span class="red">＠</span>
      </p>
      <img v-src="require('./assets/09.png')" alt="" class="snap" />
      <img v-src="require('./assets/10.png')" alt="" class="snap" />
      <p class="subtitle">
        7.进入后您会看见与客服的对话框，点击对话框便可开始与客服聊天
      </p>
      <img v-src="require('./assets/11.png')" alt="" class="snap" />
      <p>
        当你完成以上流程，恭喜你！现在就可以开始使用”SYPHON”，享受我们的专属客户服务啰！
      </p>
      <p class="faq">FAQ</p>
      <p>Q1: 为什么我无法安装”SYPHON” 软件?</p>
      <p>
        A: 1. 请检查手机版本是否太旧，iphone需要iOS 9.0
        或更高版本。Android需要4.3或更高版本，并且将手机更新到最新版本。
      </p>
      <p class="indent">
        2.
        请检查手机容量是否足够，如果没有足够的空间来下载和安装，请将设备尝试腾出空间。
      </p>
      <p></p>
      <p>Q2: 为什么我进入软件后无法登入？</p>
      <p>
        A: 1.
        请注意下载后不需注册或做任何动作，直接向在线客服联系，才能索取您专属的帐号密码，登入软件。
      </p>
      <p class="indent">
        2.进入主画面后，请注意不要点击下方绿色按键，直接点击右下角的Login，才会出现可以输入帐号密码的栏位。
      </p>
      <p class="exam-title text-center red">错误</p>
      <img v-src="require('./assets/12.png')" alt="" class="snap" />
      <p class="exam-title text-center green">正确</p>
      <img v-src="require('./assets/13.png')" alt="" class="snap" />
      <p class="indent">
        3.
        请确认帐号密码是否与在线客服提供给您的一致，所有符号及数字、字母是否复制完整如：<span
        class="red"
      >@xxx:weixin.cfd</span
      >
        完整帐号前方一定会有<span class="red">＠</span>
      </p>
      <img v-src="require('./assets/14.png')" alt="" class="snap" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'tutorial-syphon',
  data() {
    return {
      title: ['開始您專屬的在線客服', 'SYPHON 軟件教程']
    }
  },
  computed: {
    pageTitle() {
      return this.$store.state.isMobileLayout
        ? this.title
        : [this.title.join(' - ')]
    }
  },
  mounted() {
    try {
      this.$refs.video.play()
    } catch {
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  &.faq {
    color: #feb52a;
    font-size: 1.6rem;
  }

  &.indent {
    text-indent: 1rem;
  }

  &.exam-title {
    font-size: 1.4rem;
    margin-bottom: 0 !important;
  }
}
</style>
